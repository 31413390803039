import { render, staticRenderFns } from "./KeyboardF3.vue?vue&type=template&id=0f8aa550&functional=true&"
import script from "./KeyboardF3.vue?vue&type=script&lang=js&"
export * from "./KeyboardF3.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports