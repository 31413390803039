import { render, staticRenderFns } from "./AposAreaMenu.vue?vue&type=template&id=429d49a7&scoped=true&"
import script from "./AposAreaMenu.vue?vue&type=script&lang=js&"
export * from "./AposAreaMenu.vue?vue&type=script&lang=js&"
import style0 from "./AposAreaMenu.vue?vue&type=style&index=0&id=429d49a7&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "429d49a7",
  null
  
)

export default component.exports