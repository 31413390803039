var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition-group',{staticClass:"apos-admin-bar__control-set apos-admin-bar__control-set--mode-and-settings",attrs:{"tag":"div","name":"flip"}},[(!_vm.editMode)?_c('div',{key:'switchToEditMode',staticClass:"apos-admin-bar__control-set__group"},[_c('AposButton',{staticClass:"apos-admin-bar__context-button",attrs:{"label":"apostrophe:edit","type":"subtle","modifiers":['small', 'no-motion'],"tooltip":{
        content: 'apostrophe:toggleEditMode',
        placement: 'bottom'
      }},on:{"click":function($event){return _vm.switchEditMode(true)}}})],1):_vm._e(),_vm._v(" "),(_vm.editMode)?_c('div',{key:'switchToPreviewMode',staticClass:"apos-admin-bar__control-set__group"},[_c('AposDocContextMenu',{attrs:{"doc":_vm.context,"published":_vm.published,"show-preview":false}}),_vm._v(" "),(_vm.canSwitchToPreviewMode)?_c('AposButton',{staticClass:"apos-admin-bar__context-button",attrs:{"label":"apostrophe:preview","tooltip":{
        content: 'apostrophe:previewTooltip',
        placement: 'bottom'
      },"type":"subtle","modifiers":['small', 'no-motion']},on:{"click":function($event){return _vm.switchEditMode(false)}}}):_vm._e(),_vm._v(" "),(_vm.editMode)?_c('AposButton',{staticClass:"apos-admin-bar__btn apos-admin-bar__context-button",attrs:{"type":"primary","label":_vm.publishLabel,"disabled":!_vm.readyToPublish,"modifiers":['no-motion']},on:{"click":_vm.onPublish}}):_vm._e()],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }